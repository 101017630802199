<template lang="pug">
.container-fluid(style="min-height: 65vh;")
    .row
        .col-md-8.offset-md-2.col-sm-12(
            v-for="(job, index) in jobArticles"
        )
            .card.my-5.shadow(style="max-width: 100%;")
                .card-body
                    .row
                        h4 {{ job.title }}
                    .row
                        .lh-lg(v-html="job.body2")
                    .row
                        .col-6.d-flex.align-items-end
                            router-link.btn.btn-large.btn-outline-success.x-btn-background-light.stretched-link(
                                :to="`/vacatures/${job.id}`"
                            ) Lees verder
                        .col-6.d-flex.align-items-end.flex-column
                            .job-image-container
                                img.job-image(
                                    v-if="job.image"
                                    :src="job.image?.economyURL"
                                    :alt="job.title"
                                )
                .card-footer
                    p.my-2 Deze vacature is van {{ job.timestamp }}
</template>

<script>
import graphql from '../graphql'
import lib from '../lib'

const { formatDateToNL } = lib
const { apolloClient, queries } = graphql

export default {
    name : 'Jobs',
    data () {
        return {
            jobArticles : []
        }
    },
    async beforeCreate () {
        const result = await apolloClient
            .query({ query: queries.allJobArticles })
        this.jobArticles = result.data.allJobArticles.map((job) => {
            job.timestamp = formatDateToNL(job.timestamp)
            if (job.image?.publicUrl) {
                // never assume the user is aware an image needs compression
                job.image.economyURL = job.image?.publicUrl.replace('/upload/v', '/upload/q_auto:eco/v')
            }
            return job
        })
    }
}
</script>

<style lang="scss">
.job-image-container {
    width: 140px;
    height: 140px;
    overflow: hidden;
    border-radius: 9px;
    position: relative;
}

.job-image {
    height: 100%;
    width: auto;
    position: absolute;
}
</style>
